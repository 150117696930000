import * as i0 from '@angular/core';
import { inject, Injectable, InjectionToken, NgModule } from '@angular/core';
import { BehaviorSubject, Subject, map, mergeMap, timer, takeWhile, filter, tap } from 'rxjs';
import { v4 } from 'uuid';
const environment = {
  updateInterval: 150,
  // ms
  secondsShown: 8,
  // sec
  visibleCount: 3 // number
};
class Message {
  id;
  code;
  message;
  key;
  severity;
  constructor(data) {
    this.id = v4();
    this.code = data.code || null;
    this.message = data.message || null;
    this.key = data.key || null;
    this.severity = data.severity;
  }
}
class MessageService {
  messages$ = new BehaviorSubject([]);
  environment = inject(ENV);
  shown$ = new Subject();
  progresses$ = new BehaviorSubject({});
  hovered = [];
  constructor() {
    this.init();
  }
  progress$(id) {
    return this.progresses$.pipe(map(progresses => progresses[id] || null));
  }
  add(message, severity, code, key) {
    this.pushMessage(new Message({
      code,
      message,
      key,
      severity
    }));
  }
  show(id) {
    this.shown$.next(id);
  }
  hide(id) {
    this.remove(id);
  }
  clear() {
    while (this.messages$.value.length > 0) {
      this.hide(this.messages$.value[0].id);
    }
  }
  onMouseEnter(id) {
    if (this.hovered.includes(id) === false) {
      this.hovered.push(id);
    }
  }
  onMouseLeave(id) {
    if (this.hovered.includes(id) === true) {
      this.hovered.splice(this.hovered.indexOf(id), 1);
    }
  }
  pushMessage(message) {
    const messages = [message, ...this.messages$.value];
    if (messages.length > this.environment.visibleCount) {
      messages.pop();
    }
    this.messages$.next(messages);
  }
  init() {
    this.shown$.pipe(mergeMap(id => {
      this.progresses$.next({
        ...this.progresses$.value,
        [id]: 0
      });
      const totalTicks = this.environment.secondsShown * 1000 / this.environment.updateInterval;
      let ticks = totalTicks;
      return timer(0, this.environment.updateInterval).pipe(map(() => {
        if (!this.hovered.includes(id)) {
          ticks--;
        }
        this.progresses$.next({
          ...this.progresses$.value,
          [id]: ticks / totalTicks
        });
        return ticks;
      }), takeWhile(value => value >= 0, true), filter(value => value <= 0), map(() => id));
    }), tap(id => this.remove(id))).subscribe();
  }
  remove(id) {
    this.messages$.next([...this.messages$.value].filter(message => message.id !== id));
    if (this.hovered.includes(id) === true) {
      this.hovered.splice(this.hovered.indexOf(id), 1);
    }
    const progresses = {
      ...this.progresses$.value
    };
    if (progresses[id]) {
      delete progresses[id];
    }
    this.progresses$.next(progresses);
  }
  static ɵfac = function MessageService_Factory(t) {
    return new (t || MessageService)();
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: MessageService,
    factory: MessageService.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MessageService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [], null);
})();
const ENV = new InjectionToken('env.messages');
class MessagesModule {
  static forRoot(secondsShown = environment.secondsShown, visibleCount = environment.visibleCount, updateInterval = environment.updateInterval) {
    return {
      ngModule: MessagesModule,
      providers: [{
        provide: ENV,
        useValue: {
          secondsShown,
          visibleCount,
          updateInterval
        }
      }]
    };
  }
  static ɵfac = function MessagesModule_Factory(t) {
    return new (t || MessagesModule)();
  };
  static ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
    type: MessagesModule
  });
  static ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
    providers: [MessageService]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MessagesModule, [{
    type: NgModule,
    args: [{
      declarations: [],
      imports: [],
      exports: [],
      providers: [MessageService]
    }]
  }], null, null);
})();

/*
 * Public API Surface of core
 */

/**
 * Generated bundle index. Do not edit.
 */

export { ENV, Message, MessageService, MessagesModule };
